import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'
import { IssueTypes } from '@/globalVariables'
import { IndexObjection, NewObjection, Objection } from '@/types/service/objection'
import router from '@/router'
import { notification } from 'ant-design-vue'

interface DocumentDirectory {
  [key: string]: { name:string, documents: { id:number, name:string }[] };
}

export const objectionStore = defineStore('objection', () => {
  const loading = ref(false)
  const objections = ref<IndexObjection[]>([])
  const objection = ref<Objection|null>(null)
  const documents = ref<DocumentDirectory|null>(null)

  function getObjections () : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/issues').then((r) => {
      objections.value = r.data.data.filter((objection:IndexObjection) => {
        return objection.type === IssueTypes.OBJECTION
      })
    }).catch(() => {
      notification.error({
        message: 'Fout tijdens ophalen van uw bezwaren!',
        description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
        duration: 0
      })
    }).finally(() => {
      loading.value = false
    })
  }

  function getObjection (id:string|string[]) : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/objections/' + id).then((r) => {
      objection.value = r.data
    }).catch(() => {
      router.push({ name: 'Service' })
      notification.error({
        message: 'Fout tijdens ophalen van bezwaar!',
        description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
        duration: 0
      })
    }).finally(() => {
      loading.value = false
    })
  }

  function storeObjection (objection:NewObjection) : void {
    loading.value = true
    axios.post(process.env.VUE_APP_API_URL + '/issues', objection).then(() => {
      router.push({ name: 'Service' })
      notification.success({
        message: 'Bezwaar ingediend.',
        description: 'Uw bezwaar is succesvol ingediend. Wij komen zo snel mogelijk met een reactie.',
        duration: 5
      })
    }).catch(() => {
      notification.error({
        message: 'Bezwaar NIET ingediend!',
        description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
        duration: 0
      })
    }).finally(() => {
      loading.value = false
    })
  }

  function getDocuments () : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/building/documents').then((r) => {
      documents.value = r.data
    }).finally(() => {
      loading.value = false
    })
  }

  return { loading, objections, objection, documents, getObjections, getObjection, storeObjection, getDocuments }
})
